// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #bb7321;
  --ion-color-primary-rgb: 187, 115, 33;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #a5651d;
  --ion-color-primary-tint: #c28137;

  --ion-color-secondary: #af9e68;
  --ion-color-secondary-rgb: 175, 158, 104;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #9a8b5c;
  --ion-color-secondary-tint: #b7a877;

  --ion-color-tertiary: #99b437;
  --ion-color-tertiary-rgb: 153, 180, 55;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #879e30;
  --ion-color-tertiary-tint: #a3bc4b;

  --ion-color-success: #0fd10f;
  --ion-color-success-rgb: 15, 209, 15;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #0db80d;
  --ion-color-success-tint: #27d627;

  --ion-color-warning: #f6ba00;
  --ion-color-warning-rgb: 246, 186, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d8a400;
  --ion-color-warning-tint: #f7c11a;

  --ion-color-danger: #df2613;
  --ion-color-danger-rgb: 223, 38, 19;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c42111;
  --ion-color-danger-tint: #e23c2b;

  --ion-color-light: #f6f8fc;
  --ion-color-light-rgb: 246, 248, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;

  --ion-color-medium: #5f5f5f;
  --ion-color-medium-rgb: 95, 95, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #545454;
  --ion-color-medium-tint: #6f6f6f;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;
}

/* This sets a different item border color for the default theme on ios and md */
:root {
  --ion-item-border-color: var(--ion-background-color-step-200);
}

/* This sets a different background and item background for the default theme on ios */
:root.ios {
  --ion-background-color: var(--ion-background-color-step-50, #f2f2f6);
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #fff;
}

/* This sets a different background and item background for the default theme on md */
:root.md {
  --ion-background-color: var(--ion-background-color-step-100, #f9f9f9);
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #fff;
}

/* This sets a different item background when dark mode is enabled on ios and md */
@media (prefers-color-scheme: dark) {
  :root.ios,
  :root.md {
    --ion-item-background: #1c1c1d;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
  }
}
